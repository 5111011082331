//* general mixins *//

@mixin flexbox-center {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.flexbox-center {
    @include flexbox-center();
}

.default-margin {
    margin: 1rem;
}

.full-height {
    min-height: 100vh;
}

.dark-background { 
    background: #2e2e2e;
}

.login-container {
    min-width: 400px;
    width: 100%;
    @media screen and (max-width: 600px) {
        width: 100%;
        min-width: 100%;
    }
}

.form-container {
    width: 300px;
}

.button-container {
    @include flexbox-center();
    margin: 12px 0;
}
