.overview-wrapper {
  max-width: 900px;
}
.audit-gallery-container {
  display: inline-block;
  width: 240px;
  margin: 14px;
}
.audit-gallery-fotos {
  width: 240px;
  margin: 14px;
}

.status-not-available {
  &:before {
    content: '\25ef';
    color: #4fd15d;
    margin: 6px;
  }
}

.status-in-order {
  &:before {
    content: '\2b24';
    color: #4fd15d;
    margin: 6px;
  }
}

.status-not-in-order {
  &:before {
    content: '\2b24';
    color: #cd1a1a;
    margin: 6px;
  }
}

.status-not-complete {
  &:before {
    content: '\2b24';
    color: #f8db48;
    margin: 6px;
  }
}

.status-not-available {
  &:before {
    content: '\20DD';
    color: #88ff92;
    margin: 6px;
    margin-right: 16px;
  }
}

.note-icon {
  position: relative;
  top: 3px;
  margin-right: 8px;
  width: 20px;
}

.table-cell {
  width: 33%;
}

.no-data-container {
  min-width: 200px;
  margin: 14px;
  margin-left: 0;
  text-align: center;
}

.gallery-popup {
  width: 100%;
}

.media-data-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.media-container {
  width: 100%;
  max-width: 270px;
  .card-media-picture {
    width: 250px;
    height: 160px;
  }
}

.data-container {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: flex-start;
}

.special-typography {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
}

.typography-divider {
  background-color: #5c5c5c !important;
}

.typo-strong {
  font-weight: 500;
}

.typo-light {
  font-weight: 300;
}

.accordion-content {
  flex-direction: row;
  display: flex;
}

.panel-container {
  margin: 4px 0;
}
.panel-summary {
  margin: 0;
  border-radius: 4px !important;
}

.pdf-container {
  display: flex;
  margin-left: 530px;
  margin: 12px;
  align-items: 'center';
}

.damage-container {
  display: flex;
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.padded-container {
  padding: 20px;
}

.image-comment {
  margin-top: 10px;
}

.damage-type {
  text-transform: capitalize;
}

.assignee {
  flex-direction: column;
  display: flex;
  text-align: right;
}

.video-comment {
  margin-top: -15px;
  margin-bottom: 15px;
  padding-left: 20px;
}
