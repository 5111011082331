.icon-textfield-data {
  padding: 12px;
  margin: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 0.2px solid black;
  .info-textfield-container {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    .icon-textfield-label {
      margin: 0px 12px;
    }
  }
}

.machine-show-container {
  max-width: 900px;
}

.deletion-dialog {
  width: 100%;
}

.dialog-padding {
  padding: 20px;
}

.button-container {
  padding-top: 40px;
  flex-direction: row;
}

.array-textfield-container {
  display: flex;
  flex-direction: column;
}
